<template>
  <v-navigation-drawer v-model="drawer" absolute temporary right height="80vh">
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>John Leider {{ id }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item v-for="item in items" :key="item.title" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { PEOPLE_DETAILS_DRAWER_EVENT_BUS } from "./PeopleDetailsDrawerEventBus.js";

export default {
  data: () => ({
    drawer: null,
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard" },
      { title: "Photos", icon: "mdi-image" },
      { title: "About", icon: "mdi-help-box" }
    ],
    id: null
  }),
  methods: {
    changeDrawer() {
      this.drawer = !this.drawer;

      console.log(this.drawer);
    },
    showPeopleDetailsDrawer() {
      PEOPLE_DETAILS_DRAWER_EVENT_BUS.$off("showPeopleDetailsDrawer");
      PEOPLE_DETAILS_DRAWER_EVENT_BUS.$on("showPeopleDetailsDrawer", data => {
        console.log({ data });
        if (data) {
          this.id = data;
          this.drawer = !this.drawer;

          return;
        }
      });
    },
    setupEventBus() {
      this.showPeopleDetailsDrawer();
    }
  },
  mounted() {
    console.log("mounted");
    this.setupEventBus();
  }
};
</script>
